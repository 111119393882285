import React from 'react';

import './index.scss';

import logoDefault from '../../images/logo.svg';

function FinalStep({logo}) {

    return (
        <div className="final-step">
            <div className="final-step__logo">
                {logo ?
                    <img src={logo} alt="Logo"/> :
                    <img src={logoDefault} alt="Logo"/>}
            </div>
            <div className="final-step__text">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M46.5 24C46.5 36.4264 36.4264 46.5 24 46.5C11.5736 46.5 1.5 36.4264 1.5 24C1.5 11.5736 11.5736 1.5 24 1.5C36.4264 1.5 46.5 11.5736 46.5 24Z" fill="white" stroke="#65C235" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M14.5932 29.5383C14.5932 29.5383 18.0794 34.1867 23.8898 34.1867C29.7002 34.1867 33.1864 29.5383 33.1864 29.5383" stroke="#65C235" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M18 16.9175H18.0233" stroke="#65C235" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M30 16.9175H30.0233" stroke="#65C235" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <p>Thank you!</p>
                <br/>
                <p>Your answers help us eliminate poor meetings and reward excellent ones.</p>
            </div>
        </div>
    );
}

export default FinalStep;
