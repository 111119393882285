import React, { useState, useEffect} from 'react';
import './index.scss';
import moment from "moment";
import momentTZ from "moment-timezone";

function Header({stepNumber, setStepNumber, className, title, date, zone, allDay}) {

    const [activeHeader, setActiveHeader] = useState('');

    useEffect(() => {
        window.addEventListener('scroll', () => {
            let activeClass = 'active';
            if(window.scrollY === 0){
                activeClass = '';
            }
            setActiveHeader(activeClass);
        });
    });

    const buttonBack = (
        <div className="header__back-inner"
             onClick={() => setStepNumber(prev => prev - 1)}>
            <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 15L1 8L8 1" stroke="#6C7374" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    );

    const getDate = (prevTime, zone) => {

        let prevTimeZone = momentTZ.tz(zone).format('Z');
        let greenwichTime;

        if (prevTimeZone.substring(0, 1) === '+') {
            prevTimeZone = ('-' + prevTimeZone.substring(1, 6));
            greenwichTime = moment(prevTime).utcOffset(prevTimeZone).format('YYYY-MM-DDTHH:mm');
        } else {
            greenwichTime = moment(prevTime).utcOffset('+' + prevTimeZone.substring(1, 6)).format('YYYY-MM-DDTHH:mm');
        }
        return moment(greenwichTime +  '+00:00').tz(momentTZ.tz.guess()).format('YYYY-MM-DDTHH:mm');
    };

    let meetingDate = '';

    allDay ?
        meetingDate =  moment(date).format('DD MMM YYYY') + ", all day" :
        meetingDate = moment(getDate(date, zone)).format('DD MMM YYYY,  HH:mm')

    return (
        <header className={activeHeader + " header" + className}>
            <div className="header__back">
                {stepNumber === 2 ?
                    buttonBack :
                    null
                }
            </div>
            <div className="header__center">
                <div className="header__date">{meetingDate}</div>
                <h1 className="header__title">{title}</h1>
            </div>
            <div className="header__step">
                <span>{stepNumber}</span>/2
            </div>
        </header>
    );
}

export default Header;
