import React, {useEffect} from 'react';
import './index.scss';

import iconFeedback from '../../images/icon-leave-feedback.svg';
import iconHideFeedback from '../../images/icon-hide-feddback.svg';

import RateItem from "./RateItem";


function SecondStep({dataFirst, setDataPost, dataPost, setStepNumber, showTextarea, setShowTextarea}) {

    useEffect(() => {
        if (!dataPost.survey.survey_questions_attributes.length) {
            let newData = dataFirst.map(i => {
                return  {
                    title: i.title,
                    question_id: i.id,
                    value: 'neutral'
                }
            });
            setDataPost(prev => {
                return {
                    ...prev,
                    survey: {
                        ...prev.survey,
                        survey_questions_attributes: newData
                    }
                }
            })
        }


    }, []);


    let isDisabled = dataPost.survey.survey_questions_attributes.some(elem => elem.value !== 'neutral');

    const submitHandler = () => {
        setStepNumber(3);

        fetch("https://evolvemymeetings.com/api/v1/surveys", {
            method: 'POST',
            body: JSON.stringify(dataPost),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log('result', result);
                },
                (error) => {
                    console.log('error', error);
                }
            )
    };

    const showLeaveFeedback = (
        <div className="leave-feedback__inner">
                <textarea
                    className="leave-feedback__textarea"
                    placeholder="Type feedback here"
                    onChange={e => {
                        let val = e.target.value;
                        setDataPost(prev => {
                            return {
                                ...prev,
                                survey: {
                                    ...prev.survey,
                                    comment: val
                                }
                            }
                        });
                    }}
                    value={dataPost.survey.comment}
                />
            <div className="leave-feedback__title"
                 onClick={() => {
                     setShowTextarea(!showTextarea);
                     setDataPost(prev => {
                         return {
                             ...prev,
                             survey: {
                                 ...prev.survey,
                                 comment: ''
                             }
                         }
                     });
                 } }
            >
                Cancel feedback  <img src={iconHideFeedback} className="leave-feedback__icon-hide" alt="hide feedback" />
            </div>
        </div>
    );

    const hideLeaveFeedback = (
        <div className="leave-feedback__title"
             onClick={() => setShowTextarea(!showTextarea)}
        >
            Leave anonymous feedback <img src={iconFeedback} className="leave-feedback__icon-show" alt="leave feedback" />
        </div>
    );

    return (


            <div className="step step--second">
                <h2 className="step__title">Why did you rate it this way?</h2>
                <p className="step__desc">
                    Swipe or click <b>right</b> to mark as <b>“Good”</b><br/>Swipe or click <b>left</b> to mark as <b>“Needs to be improved”</b>
                </p>

                <ul className="rate-toggle">
                    {
                        dataPost.survey.survey_questions_attributes.map((i, index) => {
                            return <RateItem
                                title={i.title}
                                key={i.question_id}
                                rating={i.value}
                                data={dataPost.survey.survey_questions_attributes}
                                setDataPost={setDataPost}
                                index={index}
                                id={i.id} />
                        })
                    }
                </ul>

                <div className="leave-feedback">
                    {!showTextarea ? hideLeaveFeedback : showLeaveFeedback}
                </div>

                <div className="step__next-wrap">
                    <button className="step__next"
                            disabled={!isDisabled}
                            onClick={submitHandler}
                    >Finish survey</button>
                </div>


            </div>


    );
}

export default SecondStep;
