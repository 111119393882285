import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable'

function RateItem({title, data,  setDataPost, index}) {

    const [active, setActive] = useState('rate-toggle__item');

    const [isDefaultState, SetIsDefaultState] = useState({
        left: data[index].value === "negative" ? false : true,
        right: data[index].value === "positive" ? false : true,
        center: data[index].value === "neutral" ? false : true
    });

    useEffect(() => {
       if (!isDefaultState.left) {
           setActive('rate-toggle__item active active-left');
       }
        if (!isDefaultState.right) {
            setActive('rate-toggle__item active active-right');
        }
    });

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => leftHandler(index),
        onSwipedRight: () => rightHandler(index),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    const leftHandler = (index) => {
        if (isDefaultState.left) {
            setActive('rate-toggle__item active active-left');
            SetIsDefaultState({
                left: false,
                right: true,
                center: true
            });

            let newState = [...data];
            newState[index].value = 'negative';
            setDataPost(prev => {
                return {
                    ...prev,
                    survey: {
                        ...prev.survey,
                        survey_questions_attributes: newState
                    }
                }
            });
        }
    };

    const rightHandler = (index) => {
        if (isDefaultState.right) {
            setActive('rate-toggle__item active active-right');
            SetIsDefaultState({
                left: true,
                right: false,
                center: true
            });
            let newState = [...data];
            newState[index].value = 'positive';
            setDataPost(prev => {
                return {
                    ...prev,
                    survey: {
                        ...prev.survey,
                        survey_questions_attributes: newState
                    }
                }
            });
        }
    };

    const centerHandler = (index) => {
        if (isDefaultState.center) {
            setActive('rate-toggle__item active');
            SetIsDefaultState({
                left: true,
                right: true,
                center: false
            });
            let newState = [...data];
            newState[index].value = 'neutral';
            setDataPost(prev => {
                return {
                    ...prev,
                    survey: {
                        ...prev.survey,
                        survey_questions_attributes: newState
                    }
                }
            });
        }
    };

    return (
        <li className={active} {...swipeHandlers}>
            <div className="rate-toggle__icon rate-toggle__icon--left" onClick={() =>leftHandler(index)}>
                <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 14H5.23602C4.89515 14 4.55994 13.9129 4.26221 13.7469C3.96449 13.5809 3.71412 13.3416 3.53489 13.0516C3.35566 12.7617 3.2535 12.4308 3.23812 12.0902C3.22274 11.7497 3.29465 11.4109 3.44702 11.106L6.94702 4.106C7.11307 3.77369 7.36841 3.49419 7.68439 3.29884C8.00037 3.10349 8.36452 3.00001 8.73602 3H12.754C12.9175 3.0001 13.0804 3.02025 13.239 3.06L16.999 4M16.999 4L17 13M16.999 4H19C19.5304 4 20.0392 4.21071 20.4142 4.58579C20.7893 4.96086 21 5.46957 21 6V11C21 11.5304 20.7893 12.0391 20.4142 12.4142C20.0392 12.7893 19.5304 13 19 13H17M9.99902 14V19C9.99902 19.5304 10.2097 20.0391 10.5848 20.4142C10.9599 20.7893 11.4686 21 11.999 21H12.095C12.595 21 13 20.595 13 20.096C13 19.381 13.211 18.683 13.608 18.088L17 13" stroke="#CACACA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            <div className="rate-toggle__title"  onClick={() => centerHandler(index)}>{title}</div>
            <div className="rate-toggle__icon rate-toggle__icon--right" onClick={() => rightHandler(index)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 20L8.761 20.94C8.92 20.98 9.083 21 9.246 21H13.263C13.6347 21.0002 13.999 20.8968 14.3152 20.7014C14.6314 20.5061 14.8869 20.2265 15.053 19.894L18.553 12.894C18.7054 12.5891 18.7773 12.2503 18.7619 11.9098C18.7465 11.5692 18.6444 11.2383 18.4651 10.9484C18.2859 10.6584 18.0355 10.4191 17.7378 10.2531C17.4401 10.0871 17.1049 10 16.764 10H12V5C12 4.46957 11.7893 3.96086 11.4142 3.58579C11.0391 3.21071 10.5304 3 10 3H9.905C9.405 3 9 3.405 9 3.905C9 4.619 8.789 5.317 8.392 5.911L5 11M5 20V11M5 20H3C2.46957 20 1.96086 19.7893 1.58579 19.4142C1.21071 19.0391 1 18.5304 1 18V13C1 12.4696 1.21071 11.9609 1.58579 11.5858C1.96086 11.2107 2.46957 11 3 11H5" stroke="#CACACA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
        </li>
    );
}

export default RateItem;
