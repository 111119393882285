import React from 'react';
import './index.scss';

import emotion1 from '../../images/emotions/emotion-1.svg';
import emotion2 from '../../images/emotions/emotion-2.svg';
import emotion3 from '../../images/emotions/emotion-3.svg';
import emotion4 from '../../images/emotions/emotion-4.svg';
import emotion5 from '../../images/emotions/emotion-5.svg';

function FirsStep({dataPost, setDataPost, setStepNumber}) {

    const getRating = val => {
        setDataPost(prev => {
            return {
                ...prev,
                survey: {
                    ...prev.survey,
                    rating: val
                }
            }
        });
        setStepNumber(prev => prev + 1);
    };


    return (
        <div className="step">
            <h2 className="step__title">How was the meeting?</h2>
            <ul className="emotion">
                <li className={+dataPost.survey.rating === 5 ? "emotion__item active": "emotion__item"}
                    data-rating={5}
                    onClick={e => getRating(e.target.getAttribute('data-rating'))}>
                    <span className="emotion__title">Excellent</span>
                    <span className="emotion__icon"><img src={emotion5} alt={emotion5}/></span>
                </li>
                <li className={+dataPost.survey.rating === 4 ? "emotion__item active": "emotion__item"}
                    data-rating={4}
                    onClick={e => getRating(e.target.getAttribute('data-rating'))}>
                    <span className="emotion__title">Good</span>
                    <span className="emotion__icon"><img src={emotion4} alt={emotion4} /></span>
                </li>
                <li className={+dataPost.survey.rating === 3 ? "emotion__item active": "emotion__item"}
                    data-rating={3}
                    onClick={e => getRating(e.target.getAttribute('data-rating'))}>
                    <span className="emotion__title">Average</span>
                    <span className="emotion__icon"><img src={emotion3} alt={emotion3} /></span>
                </li>
                <li className={+dataPost.survey.rating === 2 ? "emotion__item active": "emotion__item"}
                    data-rating={2}
                    onClick={e => getRating(e.target.getAttribute('data-rating'))}>
                    <span className="emotion__title">Poor</span>
                    <span className="emotion__icon"><img src={emotion2} alt={emotion2} /></span>
                </li>
                <li className={+dataPost.survey.rating === 1 ? "emotion__item active": "emotion__item"}
                    data-rating={1}
                    onClick={e => getRating(e.target.getAttribute('data-rating'))}>
                    <span className="emotion__title">Waste of time</span>
                    <span className="emotion__icon"><img src={emotion1} alt={emotion1}/></span>
                </li>
            </ul>


        </div>
    );
}

export default FirsStep;
