import React, { useState, useEffect } from 'react';

import './App.scss';

import Header from "./components/Header";
import FirsStep from "./components/FirstStep";
import SecondStep from "./components/SecondStep";
import FinalStep from "./components/FinalStep";
import CompletedStep from "./components/CompletedStep";
import Error404 from "./components/Error404";

function App() {

    const [stepNumber, setStepNumber] = useState(1);
    const [headerClass, setHeaderClass] = useState('');
    const [error, setError] = useState(null);
    const [showTextarea, setShowTextarea] = useState(false);
    const [meetingInfo, setMeetingInfo] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const [presence, setPresence] = useState('');

    const urlString = window.location;
    const token = new URLSearchParams(urlString.search).get('token');

    const isNoSurveyToken = new URLSearchParams(urlString.search).get('rate_meeting');

    const [data, setData] = useState([]);



    const [dataPost, setDataPost] = useState({
        survey: {
            rating: 0,
            comment: '',
            survey_questions_attributes: []
        },
        token: token
    });

    let url = new URL("https://evolvemymeetings.com/api/v1/user_meetings/")  + token;

    useEffect(() => {
        setPresence(new URLSearchParams(urlString.search).get('presence'));
        let presence = new URLSearchParams(urlString.search).get('presence');
        if (presence === 'true') {
            presence = 'yes'
        }
        if (presence === 'false') {
            presence = 'no'
        }

        if (presence) {
            fetch('https://evolvemymeetings.com/api/v1/presence_at_meeting?token=' + token + '&presence=' + presence,
                {
                    method: 'PATCH'
                })
                .then(res => res.json())
                .then(
                    (result) => {
                        setIsLoaded(true);
                        // setMeetingInfo(result);
                    },
                    (error) => {
                        setIsLoaded(true);
                        setError(error);
                    }
                )
        }

    }, []);

    useEffect(() => {
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setMeetingInfo(result);

                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [url]);


    useEffect(() => {
        fetch('https://evolvemymeetings.com/api/v1/questions')
            .then(res => res.json())
            .then(
                (result) => {
                    // setIsLoaded(true);
                    setData(result);
                },
                (error) => {
                    // setIsLoaded(true);
                    setError(error);

                }
            )
    }, []);

    let content;
    if (stepNumber === 1) {
        content = <FirsStep
            dataPost={dataPost}
            setDataPost={setDataPost}
            setStepNumber={setStepNumber} />
    } else if (stepNumber === 2) {
        content = <SecondStep
            dataFirst={data}
            dataPost={dataPost}
            showTextarea={showTextarea}
            setShowTextarea={setShowTextarea}
            setDataPost={setDataPost}
            setStepNumber={setStepNumber}
            setHeaderClass={setHeaderClass}/>
    } else {
        content = <FinalStep logo={meetingInfo.meeting_company_logo} />
    }

    return (
        <div className="main-block">

            {isLoaded ?

                meetingInfo.errors ?
                    <Error404/> :
                    presence ?
                        <CompletedStep
                            text="Thank you for your response"
                            logo={meetingInfo.meeting_company_logo}
                        />  :
                        meetingInfo.completed  ?
                            <CompletedStep
                                text="You've already completed this survey"
                                logo={meetingInfo.meeting_company_logo}
                            /> :
                            isNoSurveyToken === 'false' ?
                                <CompletedStep
                                    text="Thank you. Hope to see you next time."
                                    logo={meetingInfo.meeting_company_logo}
                                /> :
                                <>
                                    {
                                        stepNumber === 3 ?
                                            null :
                                            <Header
                                                className={headerClass}
                                                stepNumber={stepNumber}
                                                setStepNumber={setStepNumber}
                                                title={meetingInfo.meeting_subject}
                                                date={meetingInfo.start_date}
                                                allDay={meetingInfo.all_day}
                                                zone={meetingInfo.meeting_time_zone}
                                            />
                                    }

                                    {content}
                                </>

                 :
                <div className="loader"></div>
            }

        </div>
      );
    }

export default App;
