import React from 'react';

import './index.scss';

import logoDefault from '../../images/logo.svg';


function Error404() {

    return (
        <div className="final-step">
            <div className="final-step__logo">
                <img src={logoDefault} alt="Logo"/>
            </div>
            <div className="final-step__text">
                <h1>404</h1>
            </div>
        </div>
    );
}

export default Error404;
